

import { Component, HostListener, OnInit } from '@angular/core';
import { NavigationService } from 'src/app/shared/services/navigation.service';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { trigger, transition, style, animate, query } from '@angular/animations';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { DataService } from 'src/app/shared/services/data.service';
import { SharedDataService } from 'src/app/shared/services/shared-data.service';
import { filter } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { BottomBarCardService,  } from 'src/app/shared/services/bottombar.card.service';


@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],

})

export class LayoutComponent implements OnInit {
  isBottomBarVisible = true; // Flag to control the visibility of the bottom bar
  scrollTimeout: any; // Timeout reference for controlling bottom bar visibility on scroll

  isActive: boolean = true; // Flag for toggling animation state
  reportData: any; // Data for reports (currently unused)
  isLoaded: boolean = false; // Flag to indicate if data is loaded
  userDetail: any; // Details of the logged-in user
  systemStatus: string = ''; // Status of the system (currently unused)
  currentRoute: string = ''; // Current active route
  // Array of menu items
  menus = [{
    icon: 'icon-Home',
    name: 'Menu 1',
    path: '/main/home'
  }, {
    icon: 'icon-Reports',
    name: 'Menu 2',
    path: '/main/calender'
  }, {
    icon: 'icon-Alerts',
    name: 'Menu 3',
    path: '/main/clock'
  }, {
    icon: 'icon-System_1',
    name: 'Menu 4',
    path: '/main/user'
  }];

  clockInDetail: any={};
  resourceDetail:any;
  isClockedIn: boolean=false;
  isBreak: boolean=false;
  todaysTotalClockedInMinutes: string='';
  totalClockedInMinutesPerTask: string='';
  currentClockInMinutes: string = '00:00:00'; // Default starting time
  todaysTotalBreakMinutes: string='';
  private timer: any; // Holds the timer reference
  private currentClockInSubject: BehaviorSubject<string> = new BehaviorSubject<string>(this.currentClockInMinutes);

  constructor(
    private dialogService: DialogService, // Service for handling dialogs
    public activatedRoute: ActivatedRoute, // Angular service for accessing route parameters
    private router: Router, // Angular service for navigation
    private dataService: DataService, // Service for making API requests
    private nav: NavigationService, // Service for handling navigation
    private auth: AuthService, // Service for authentication
    private sharedData: SharedDataService, // Service for shared data across components
    
    private bottombarCard: BottomBarCardService
  ) {
// Attach a scroll event listener to handle bottom bar visibility
    window.addEventListener('scroll', this.onWindowScroll, true);

    // Subscribe to the service to toggle the animation
    this.bottombarCard.refeshClockInAnimationState$.subscribe((state) => {
      console.log('code rurrrrrrrrrrrrrrrrrrrrrrrrrrrrrr')
      this.sharedData.restoreSharedData()
      this.stopTimer()
      this.isClockedIn=false;
      this.isBreak=false;
      this.todaysTotalClockedInMinutes='';
      this.totalClockedInMinutesPerTask='';
      this.currentClockInMinutes = '00:00:00'; // Default starting time
      this.todaysTotalBreakMinutes='';
      this.clockInDetail=  this.sharedData.getAttribute('clockInDetail')
      this.resourceDetail=  this.sharedData.getAttribute('resourceDetail')
      this.processClockInDetail(false)
    });
  }
   /**
   * Handles scroll events to hide and show the bottom bar.
   */
  onWindowScroll = () => {
    clearTimeout(this.scrollTimeout); // Clear previous timeout
    this.isBottomBarVisible = false; // Hide bottom bar on scroll

    // Set a timeout to show the bottom bar after scrolling stops
    this.scrollTimeout = setTimeout(() => {
      this.isBottomBarVisible = true;
    }, 500); // Adjust the delay as needed
  }
  /**
   * Removes the scroll event listener when the component is destroyed.
   */
  ngOnDestroy(): void {
    window.removeEventListener('scroll', this.onWindowScroll, true);
  }
  activeIndex: number = 0; // Index of the currently active menu item
  activeMenu: any = null; // The currently active menu item
  timeDifference=0;

  ngOnInit() {

 // Retrieve user details on initialization

    this.userDetail = this.auth.getUserDetail();
    // this.getReportData();
  // Retrieve the stored index of the active menu item
    const storedIndex = localStorage.getItem('activeIndex');
    if (storedIndex !== null) {
      this.activeIndex = parseInt(storedIndex, 10);
    }
// Subscribe to router events to update the active menu on route change
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd)
      )
      .subscribe((event: any) => {
        this.currentRoute = event.url;
        this.activeMenu = this.getActiveMenu(); // Update active menu on route change
        const activeMenuIndex = this.menus.findIndex(menu => menu.path === this.activeMenu?.path);
        if(activeMenuIndex>=0 &&activeMenuIndex<=3){
          this.activeIndex = activeMenuIndex; // Update the activeIndex when a menu is clicked
        localStorage.setItem('activeIndex', this.activeIndex.toString());
        }

      });
      let usrObj = this.sharedData.getAttribute('usrObj');
     
        this.auth.getDetails(
          usrObj.ClockStatus.addressId,
          usrObj.ClockStatus.quoteId,
          usrObj.ClockStatus.taskId,
          usrObj.ClockStatus.resourceId,
          usrObj.ClockStatus.resourceType
        );
      
  }
   /**
   * Finds and returns the menu item that matches the current route.
   * @returns The active menu item or undefined
   */
  getActiveMenu() {
    return this.menus.find(menu => this.currentRoute.includes(menu.path));
  }

/**
   * Navigates to the selected menu item and updates the active index.
   * @param menu The selected menu item
   * @param index The index of the selected menu item
   */
  goToMenu(menu: any, index: number) {
    this.activeIndex = index;
    localStorage.setItem('activeIndex', this.activeIndex.toString());
    if (menu.path) {
      this.nav.navigateTo([menu.path]);
    }
  }
   /**
   * Toggles the animation state.
   */
  toggleAnimation() {
    this.isActive = !this.isActive;
  }
  stopTimer(): void {
    if (this.timer) {
      clearInterval(this.timer); // Stop the timer
      this.timer = null; // Reset the timer reference
    }
  }
  getTime = (totalMinutes: number = 0, needSeconds: boolean = false): string => {
    // Calculate total hours and minutes
    let minutes:any = Math.trunc(totalMinutes / 60);
    minutes = minutes < 10 ? ('0' + minutes) : minutes;
  
    let hours:any = Math.trunc(totalMinutes % 60);
    hours = hours < 10 ? ('0' + hours) : hours;
  
    // Format the time string as "mm:hh"
    let time = minutes + ':' + hours;
  
    // If needSeconds is true, append seconds to the time string
    if (needSeconds) {
      const seconds = Math.round((totalMinutes % 1) * 60); // Calculate the fractional part of totalMinutes to get seconds
      time += ':' + (seconds < 10 ? ('0' + seconds) : seconds);
    }
  
    return time;
  };
  
  processClockInDetail = (isStopTimer: boolean): void => {
    // Destructure for clarity
    const { clockIn, break: isOnBreak, todaysTotalClockedInMinutes,time_stamp, totalClockedInMinutesPerTask, currentClockInMinutes, todaysTotalBreakMinutes } = this.clockInDetail;
    this.isClockedIn = clockIn;
    this.isBreak = isOnBreak;
    this.todaysTotalClockedInMinutes = this.getTime(todaysTotalClockedInMinutes);
    this.totalClockedInMinutesPerTask = this.getTime(totalClockedInMinutesPerTask);
    this.timeDifference = this.getTimeDifferenceInMinutes(time_stamp);
    this.currentClockInMinutes = this.getTime(currentClockInMinutes+this.timeDifference, true);
    this.todaysTotalBreakMinutes = this.getTime(todaysTotalBreakMinutes);
  console.log(isStopTimer)
    // Stop the timer if necessary
    if (isStopTimer) {

      this.stopTimer();
    }

    // Start the timer if clocked in or on break
    if (this.isClockedIn || this.isBreak) {
      console.log('this code not runs')
      this.startTimer();
    }
  };
  startTimer(): void {
    // Extract current time from the currentClockInMinutes in HH:MM:SS format
    let [hours, minutes, seconds] = this.currentClockInMinutes.split(':').map((unit) => parseInt(unit, 10));
  
    this.timer = setInterval(() => {
      seconds++;
  
      if (seconds === 60) {
        seconds = 0;
        minutes++;
      }
  
      if (minutes === 60) {
        minutes = 0;
        hours++;
      }
  
      // Update the currentClockInMinutes with the new time
      this.currentClockInMinutes = `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
      this.currentClockInSubject.next(this.currentClockInMinutes); // Emit updated time
    }, 1000); // Update every second
  }
  getTimeDifferenceInMinutes(timeStamp: string): number {
    const currentTime = new Date();
    const givenTime = new Date(timeStamp);

    // Calculate the difference in milliseconds
    const timeDiffInMs = Math.abs(currentTime.getTime() - givenTime.getTime());

    // Convert milliseconds to minutes (divide by 1000 * 60)
    return timeDiffInMs / (1000 * 60);
  }
}
import { Injectable, NgZone } from '@angular/core';

import { SharedDataService } from './shared-data.service';
import { NotificationService } from './notification.service';
import { Observable } from 'rxjs';
// import { LoadingService } from './loader.service';
import { ApiUrls } from '../constants/apiUrl.constants';
import { NavigationService } from './navigation.service';
import { DataService } from './data.service';
import { LoadingService } from './loader.service';
import { Router } from '@angular/router';
import { BobjMapService } from './bobj.map.service';
import { BottomBarCardService } from './bottombar.card.service';

@Injectable()
export class AuthService {
  redirectUrl: string; // URL to redirect after login
  isChecked = false; // Flag to indicate if a login or signup process is ongoing
  // tslint:disable-next-line:max-line-length
  constructor(
    private sharedData: SharedDataService,
    private nav: NavigationService,
    private dataService: DataService,
    private notification: NotificationService,
    private bottombarCard: BottomBarCardService,

    private ngZone: NgZone,
    private router: Router,
    private bobjMapService: BobjMapService,
    private loader: LoadingService
  ) {
    // Setting default route to navigate after login
    this.redirectUrl = '/main/home/components';
  }
  /**
   * Retrieves the access token from shared data.
   * @returns The access token string if available.
   */
  getAccessToken() {
    return this.sharedData.getAttribute('accessToken');
  }
  /**
   * Logs in a user with the provided credentials.
   * @param userData User credentials including email and password.
   */
  loginJt(userData: any) {
    this.dataService
      .post({
        url: ApiUrls.Jt_Login,
        data: userData,
        isLoader: true,
        donot_stop_loader: true,
      })
      .subscribe(
        (response: any) => {
          const result = response.result;

          console.log(result);
          if (response.result !== undefined) {
            this.sharedData.setAttribute('jtUserDetails', result);
            this.sharedData.setAttribute('accessToken', result.access_token);
            this.sharedData.setAttribute('refreshToken', result.refresh_token);
            this.loginData();
            this.getApplicationObj();
          } else {
            this.notification.toast(response.error);
          }
          this.isChecked = false;
        },
        () => {
          this.isChecked = false;
        }
      );
  }

  loginData() {
    let data = {
      noVerData: true,
    };
    this.dataService
      .get({
        url: ApiUrls.Jt_Login_Data + '?',
        isLoader: true,
        donot_stop_loader: true,
        data: data,
      })
      .subscribe(
        (response: any) => {
          const result = response.result;

          console.log(result);
          if (response.result !== undefined) {
            this.sharedData.setAttribute('userObj', result.hooks);
            this.sharedData.setAttribute('bObj', result.hooks.bzobj);
            this.sharedData.setAttribute('usrObj', result.hooks.usrObj);
            this.sharedData.setAttribute('rObj', result.hooks.reasonObj);
            this.bobjMapService.mapBusinessData();
            this.bobjMapService.mapUserData();
            this.bobjMapService.mapReasonData();
            this.getResourceDetails(result.hooks.usrObj?.resourceId);

          } else {
            this.notification.toast(response.error);
          }
          this.isChecked = false;
        },
        () => {
          this.isChecked = false;
        }
      );
  }
  getApplicationObj() {
    let data = {
      noVerData: true,
    };
    this.dataService
      .get({
        url: ApiUrls.Applocation_Constants + '?',
        isLoader: false,
        data: data,
      })
      .subscribe(
        (response: any) => {
          const result = response.result;

          if (response.result !== undefined) {
            this.sharedData.setAttribute('aObj', result);
          } else {
            this.notification.toast(response.error);
          }
          this.isChecked = false;
        },
        () => {
          this.isChecked = false;
        }
      );
  }

  checkSystem() {
    this.dataService
      .get({
        url: ApiUrls.Attached_System,
        isLoader: true,
        donot_stop_loader: true,
      })
      .subscribe((response: any) => {
        this.sharedData.setAttribute('date-filter', {
          label: 'Weekly',
          graph: 'weekly',
          value: 20,
          isSelected: true,
          type: 'static',
        });
        if (response.result.length) {
          console.log('byhhhhhhhhhhhhhhhhhhhhhh');
          if (this.sharedData.getAttribute('selectedAttachedSystems')) {
          } else {
            this.sharedData.setAttribute(
              'selectedAttachedSystems',
              response.result[0].id
            );
            this.sharedData.setAttribute(
              'selectedSystemObj',
              response.result[0]
            );
          }
          this.sharedData.setAttribute('attachedSystems', response.result);
          this.nav.navigateTo([this.redirectUrl]);
          //this.shareSystemHaveSystem()
        } else {
          this.sharedData.setAttribute('attachedSystems', []);
          this.shareSystem();

          //  this.nav.navigateTo(['/system-mapping-wizard/components']);
        }
      });
  }
  /**
   * Navigates to the invitation acceptance page if there are invited systems.
   * If no invited systems, redirects to the appropriate page based on system status.
   */
  shareSystemHaveSystem() {
    let userObj = this.getUserDetail();
    this.dataService
      .get({
        url: ApiUrls.Invites + '?' + 'invite_to=' + userObj.email + '&',
        isLoader: true,
      })
      .subscribe((response: any) => {
        let systemsShareSystemsInvited = response.result.records;
        systemsShareSystemsInvited = systemsShareSystemsInvited?.filter(
          (system: any) => system.status == 'invited'
        );

        if (systemsShareSystemsInvited.length > 0) {
          this.nav.navigateTo(['/accept-invitation']);
        } else {
          this.nav.navigateTo([this.redirectUrl]);
        }
      });
  }
  /**
   * Shares system details and navigates to the appropriate page based on system acceptance or invitation status.
   */
  shareSystem() {
    let userObj = this.getUserDetail();
    this.dataService
      .get({
        url: ApiUrls.Invites + '?' + 'invite_to=' + userObj.email + '&',
        isLoader: true,
      })
      .subscribe((response: any) => {
        let systemsShareSystemsAccepted = response.result.records;
        let systemsShareSystemsInvited = response.result.records;

        systemsShareSystemsAccepted = systemsShareSystemsAccepted?.filter(
          (system: any) => system.status === 'accept'
        );
        systemsShareSystemsInvited = systemsShareSystemsInvited?.filter(
          (system: any) => system.status === 'invited'
        );

        console.log(systemsShareSystemsAccepted.length); // Corrected property name
        console.log(systemsShareSystemsAccepted);

        if (systemsShareSystemsAccepted.length > 0) {
          this.sharedData.setAttribute(
            'selectedAttachedSystems',
            systemsShareSystemsAccepted[0]?.system_details?.id
          );
          this.sharedData.setAttribute(
            'selecteSystemObj',
            systemsShareSystemsAccepted[0]?.system_details
          );
          this.nav.navigateTo(['/main/home/components']);
          return;
        }

        if (systemsShareSystemsInvited.length > 0) {
          this.nav.navigateTo(['/accept-invitation']);
        } else {
          console.log('hanu');
          this.nav.navigateTo(['/system-mapping-wizard/components']);
        }
      });
  }
  //  sign - up
  /**
   * Signs up a new user with the provided information.
   * @param userData User information including email, password, etc.
   */
  signUp(userData: any) {
    if (!userData.terms) {
      this.notification.toast(
        'Please agree with Terms Of Use and Privacy Policy!'
      );
      return;
    }
    userData.gender = 'male';
    //userData.phone = "1234567890";
    userData.email = userData.email.toLowerCase();

    this.isChecked = true;
    if (userData.middle_name == '') {
      delete userData.middle_name;
    }
    if (userData.middle_name == null) {
      delete userData.middle_name;
    }
    // Referencing this in this because this refers to the promise itthis. Do this let this = this;
    const data = {
      value: userData,
      withCredentials: false,
    };

    this.dataService
      .post({ url: ApiUrls.SignUp, data: data, isLoader: true })
      .subscribe(
        (response: any) => {
          const result = response.result;

          if (response.result !== undefined) {
            this.notification.toast(
              'Your account has been created successfully'
            );
            this.nav.navigateTo(['/auth/login']);
          } else {
            this.notification.toast(response.error);
          }
          this.isChecked = false;
        },
        () => {
          this.isChecked = false;
        }
      );
  }

  /**
   * Sends an email verification request.
   */
  sentEmailVerification() {
    this.dataService
      .post({ url: ApiUrls.Email_OTP })
      .subscribe((response: any) => {
        if (response && response.result) {
        } else {
          this.notification.toast(response.error, 'danger');
        }
      });
  }
  /**
   * Sets a cookie with the given name, value, and expiration days.
   * @param name Cookie name.
   * @param value Cookie value.
   * @param days Number of days until the cookie expires.
   */
  setCookie(name: string, value: string, days: number) {
    const expires = new Date();
    expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);
    document.cookie = `${name}=${value};expires=${expires.toUTCString()};path=/`;
  }
  /**
   * Retrieves the value of a cookie with the given name.
   * @param name Cookie name.
   * @returns Cookie value if found, otherwise null.
   */
  getCookie(name: string): string | null {
    const cookies = document.cookie.split(';');
    for (const cookie of cookies) {
      const [cookieName, cookieValue] = cookie.trim().split('=');
      if (cookieName === name) {
        return cookieValue;
      }
    }
    return null;
  }
  /**
   * Verifies the user's email with the provided passcode.
   * @param passcode Email verification passcode.
   */
  emailVerify(passcode: any) {
    const userDetail =
      this.sharedData.getAttribute('userDetails')?.user_details;
    this.dataService
      .get({
        url:
          ApiUrls.Email_Verification +
          '/' +
          userDetail.email.toLowerCase() +
          '/' +
          passcode,
      })
      .subscribe((response: any) => {
        if (response && response.result) {
          this.setCookie('IsFirstTimeGetStarted', 'true', 365);
          var user_details = this.sharedData.getAttribute('userDetails');
          user_details.user_details.is_email_verified = true;
          this.sharedData.setAttribute('userDetails', user_details);
          this.sharedData.restoreSharedData();
          this.nav.navigateTo(['/get-started/']);
        } else {
          // this.nav.navigateTo(['/get-started/']);
          this.notification.toast(response.error, 'danger');
        }
      });
  }

  /**
   * Retrieves application constants and stores them in shared data.
   */
  getApplicationConstant() {
    this.dataService
      .get({
        url: ApiUrls.Application_Constant,
        isLoader: false,
      })
      .subscribe((response: any) => {
        if (response && response.result) {
          this.sharedData.setAttribute('applicationConstant', response.result);
        }
      });
  }
  /**
   * Logs out the user, clears shared data, and navigates to the login page.
   */
  redirectAfterLogout() {
    this.ngZone.run(() => {
      // this.loader.stop();
      console.log('Refresh Token expired');
      this.sharedData.clear();
      this.nav.navigateTo(['/auth/login']);
    });
  }
  /**
   * Checks if the user is logged in based on the presence of an access token and user details.
   * @returns True if logged in, otherwise false.
   */
  isLoggedIn() {
    return Boolean(
      this.sharedData.getAttribute('accessToken') &&
        this.sharedData.getAttribute('jtUserDetails')
    );
  }
  /**
   * Retrieves the refresh token from shared data.
   * @returns The refresh token string if available.
   */
  getRefreshToken() {
    return this.sharedData.getAttribute('refreshToken');
  }
  /**
   * Refreshes the access token using the refresh token.
   * @returns An observable with the new access token.
   */
  refreshAccessToken(): Observable<any> {
    const data = { refresh_token: this.getRefreshToken() };
    return this.dataService.post({
      url: ApiUrls.Refresh_Token,
      data,
      isLoader: false,
    });
  }
  /**
   * Logs out the user by sending a logout request and redirects to the login page.
   */
  // logout() {

  //   this.dataService.post({ url: ApiUrls.User_Logout }).subscribe((result: any) => {
  //     if (result) {
  //       this.sharedData.clear();
  //       this.isChecked = false;

  //     window.location.replace('/auth/login');
  //     }
  //   });
  // }
  logout() {
    this.sharedData.clear();
    this.isChecked = false;

    window.location.replace('/auth/login');
  }
  /**
   * Resets the user's password with the provided information.
   * @param userData New password and related information.
   */
  resetPassword(userData: any) {
    const data = {
      value: userData,
      withCredentials: false,
    };

    this.dataService
      .post({ url: ApiUrls.Password_Reset, data: {data:JSON.stringify(userData)} })
      .subscribe(
        (result: any) => {
          if (result?.error) {
            this.notification.toast(result?.error);
          } else {
            this.nav.navigateTo(['/auth/login']);
            this.notification.toast('Your password has been reset!.');
          }
        },
        () => {}
      );
  }


    /**
   * Resets the user's password with the provided information.
   * @param userData New password and related information.
   */
    changePassword(userData: any) {
      delete userData.password_confirmation
      
  
      this.dataService
        .post({ url: ApiUrls.Change_password+"/"+this.sharedData.getAttribute('resourceDetail').id, data: {data:JSON.stringify(userData)} })
        .subscribe(
          (result: any) => {
            if (result?.error) {
              this.notification.toast(result?.error);
            } else {
              this.notification.toast('Your password has been reset!.');
              this.nav.back()
            }
          },
          () => {}
        );
    }
  /**
   * Initiates a password reset request for the user.
   * @param userData User information including email for password reset.
   */
  // forgetPassword(userData: any) {
  //   // Referencing this in self because this refers to the promise itself. Do this let self = this;
  //   const self = this,
  //     data = {
  //       value: userData,
  //       noCredentials: true
  //     };
  //   data.value.email = data.value.email.toLowerCase()
  //   this.dataService.post({ url: ApiUrls.Forget_Password, data }).subscribe(
  //     (response: any) => {
  //       if (response && response.result) {

  //         self.notification.toast('Please check your mail for reset link!');
  //         this.sharedData.setAttribute('forgetPasswordEmail', userData.email);
  //         this.nav.navigateTo(['/auth/reset-password']);
  //       } else if (response.error) {
  //         self.notification.toast(response.error, 'danger');
  //       }
  //     }
  //   );
  // }
  forgetPasswordJt(userData: any) {
    // Referencing this in self because this refers to the promise itself. Do this let self = this;
    const self = this,
      data = {
        value: userData,
        noCredentials: true,
      };
    data.value.email = data.value.email.toLowerCase();
    this.dataService
      .post({ url: ApiUrls.Forget_Password, data:{email:userData.email.toLowerCase()} })
      .subscribe((response: any) => {
        if (response && response.result) {
          self.notification.toast('Please check your mail for reset link!');
          this.sharedData.setAttribute('forgetPasswordEmail', userData.email);
          this.nav.navigateTo(['/auth/reset-password']);
        } else if (response.error) {
          self.notification.toast(response.error, 'danger');
        }
      });
  }
  /**
   * Updates the user's password with the provided new password.
   * @param password New password information.
   */
  updatePassword(password: any) {
    const userDetail = this.sharedData.getAttribute('jtUserDetails');
    console.log(userDetail);
    let resetObj = {
      password: password.new_password,
    };
    let data1 = {
      data: resetObj,
    };

    const data = {
      value: data1,
      noCredentials: false,
    };
    this.dataService
      .post({ url: ApiUrls.Admin_Password_Change + '/' + userDetail?.id, data })
      .subscribe((response: any) => {
        if (response && response.result) {
          this.notification.toast('Password updated successfully!');
          this.nav.navigateTo(['/auth/login']);
          this.sharedData.deleteAttribute('forgetPasswordEmail');
        } else {
          this.notification.toast(response.error, 'danger');
        }
      });
  }
  /**
   * Retrieves the details of the currently logged-in user.
   * @returns User details if logged in, otherwise an empty object.
   */
  getUserDetail() {
    return this.isLoggedIn()
      ? this.sharedData.getAttribute('jtUserDetails').user_details
      : {};
  }
  /**
   * Updates the profile information of the logged-in user.
   * @param formData Updated user profile data.
   * @returns An observable with the update result.
   */
  updateLoginUser(formData: any) {
    return this.dataService.put({
      url: ApiUrls.Profile_Update,
      data: formData,
      loaderName: 'container-loader',
    });
  }

  getResourceDetails(resourceId: string) {
    let url = ApiUrls.Resources + '/' + resourceId + '?';
    this.dataService
      .get({
        url: url,
        isLoader: true,
        donot_stop_loader: true,
      })
      .subscribe(
        (response: any) => {
          if (response && response.result) {
    this.sharedData.restoreSharedData()

            this.sharedData.setAttribute('resourceDetail', response.result);
            let usrObj = this.sharedData.getAttribute('usrObj');
            if (usrObj.ClockStatus.status == 'clock_in') {

              // this.getDetails(
              //   usrObj.ClockStatus.addressId,
              //   usrObj.ClockStatus.quoteId,
              //   usrObj.ClockStatus.taskId,
              //   usrObj.ClockStatus.resourceId,
              //   usrObj.ClockStatus.resourceType
              // );
            }else{
              this.nav.navigateTo([this.redirectUrl]);
            }
          } else {
           // this.nav.navigateTo([this.redirectUrl]);

          }
        },
        (error) => {
          if (error.error) {
          }
        }
      );
  }

  // Method to get details
  async getDetails(
    addressId: string,
    quoteId: string,
    serviceId: string,
    resourceId: string,
    resourceType: string
  ): Promise<void> {
    const url = `jacktrade/api/timeTrackingApi/track/clockIn/${addressId}/${quoteId}/${serviceId}?resourceId=${resourceId}&resourceType=${resourceType}&`;

    try {
      // Make the API call using the dataService
      const response: any = await this.dataService
        .get({ url, isLoader: true,
          donot_stop_loader: true, })
        .toPromise();
      if (response?.result) {
        let clockInDetail = response.result;
        clockInDetail.time_stamp = new Date();
        if (response.result.clockIn == true) {
          this.sharedData.setAttribute('clockInDetail', clockInDetail);
          this.refreshClockIn();
        }
        this.nav.navigateTo([this.redirectUrl]);

        //this.processClockInDetail(isStopTimer);
      } else {
      }
    } catch (error) {}
  }
  refreshClockIn() {
    this.bottombarCard.refreshClockIn(); // Directly call the method
  }
}

import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule, provideAnimations } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { HttpClientModule } from '@angular/common/http';
import { LayoutComponent } from './pages/layout/layout.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';
import { NgxUiLoaderConfig, NgxUiLoaderModule, POSITION, SPINNER } from 'ngx-ui-loader';
import { HammerModule, HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import * as Hammer from 'hammerjs';
import { WebcamModule } from 'ngx-webcam';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ServiceWorkerModule } from '@angular/service-worker';
import { RouterLink, RouterOutlet } from '@angular/router';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MatCardModule } from '@angular/material/card';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatGridListModule } from '@angular/material/grid-list';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
export class MyHammerConfig extends HammerGestureConfig {
  override overrides = {
    'pan': { direction: Hammer.DIRECTION_VERTICAL },
    'pinch': { enable: false },
    'rotate': { enable: false },
    'press': { enable: false },
    'tap': { enable: false },
    'doubletap': { enable: false },

    'swipe': { direction: Hammer.DIRECTION_HORIZONTAL }
  };
}




// const ngxUiLoaderConfig: NgxUiLoaderConfig = {
//   bgsColor: '#fd6b00',
//   bgsPosition: POSITION.centerCenter,
//   bgsSize: 80,
//   bgsType: SPINNER.ballScaleMultiple, // background spinner type
//   fgsColor: '#fd6b00',
//   fgsType: SPINNER.ballScaleMultiple, // foreground spinner type
//   fgsSize: 80,
//   overlayColor: 'rgb(255,255,255)',
//   hasProgressBar: false,
//   maxTime: -1,
//   minTime: 500
// };
const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsColor: '#fd6b00',
  bgsPosition: POSITION.centerCenter,
  bgsSize: 80,
  bgsType: SPINNER.rectangleBounce, // background spinner type
  fgsColor: '#fd6b00',
  fgsType: SPINNER.rectangleBounce, // foreground spinner type
  fgsSize: 80,
  overlayColor: 'rgb(255,255,255)',
  hasProgressBar: false,
  maxTime: -1,
  minTime: 500,
  //overlayColor: 'rgba(40, 40, 40, 0.8)',
  //logoUrl: '../assets/images/Alerts-5.png'
};
@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    PageNotFoundComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    AppRoutingModule,
    RouterLink, RouterOutlet,
    BrowserAnimationsModule,
    SharedModule, HammerModule, HammerModule, WebcamModule,
    ImageCropperModule,
    MatCardModule,
    MatSidenavModule,
    MatGridListModule,
    HttpClientModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    NgxWebstorageModule.forRoot({
      prefix: 'greyterMobile',
      separator: '.',
      caseSensitive: true
    }),
    InfiniteScrollModule,
    MatPasswordStrengthModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [provideAnimations(), {
    provide: HAMMER_GESTURE_CONFIG,
    useClass: MyHammerConfig
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }

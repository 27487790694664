import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ModuleAccessGuard } from './shared/guards/module-access.guard';
import { LayoutComponent } from './pages/layout/layout.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';

const routes: Routes = [{
  path: 'main',
  component: LayoutComponent, data: { animation: 'isRight' },
  children: [
    {
      path: 'home',
      loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule),
      canLoad: [ModuleAccessGuard]
    },
    // {
    //   path: 'home/assignment-detail',
    //   loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule),
    //   canLoad: [ModuleAccessGuard]
    // },
    {
      path: 'report',
      loadChildren: () => import('./pages/report/report.module').then(m => m.ReportModule),
      canLoad: [ModuleAccessGuard]
    },
    {
      path: 'user',
      loadChildren: () => import('./pages/user-profile/user-profile.module').then(m => m.UserProfileModule),
      canLoad: [ModuleAccessGuard]
    },
    {
      path: 'clock',
      loadChildren: () => import('./pages/clockin-status/clockin-status.module').then(m => m.ClockInDetailModule),
      canLoad: [ModuleAccessGuard]
    },
    {
      path: 'calender',
      loadChildren: () => import('./pages/calender/calender.module').then(m => m.CalenderModule),
      canLoad: [ModuleAccessGuard]
    },
    {
      path: 'system-connectivity',
      loadChildren: () => import('./pages/system-connectivity/system-connectivity.module').then(m => m.SystemConnectivityModule),
      canLoad: [ModuleAccessGuard]
    },
    {
      path: 'app-charts',
      loadChildren: () => import('./pages/all-charts/all-charts.module').then(m => m.AllChartsModule),
      canLoad: [ModuleAccessGuard]
    },
    {
      path: 'setup/support-request',
      loadChildren: () => import('./pages/support-request/support-request.module').then(m => m.SupportRequestModule),
    //  canLoad: [ModuleAccessGuard]
    
    },

    {
      path: 'support-landing',
      loadChildren: () => import('./pages/support-landing/support-landing.module').then(m => m.SupportLandingModule),
      canLoad: [ModuleAccessGuard]

    },
   
    {
      path: 'setup/account-deletion',
      loadChildren: () => import('./pages/delete-account/delete-account.module').then(m => m.DeleteAccountModule),
      canLoad: [ModuleAccessGuard]
    },
    {
      path: 'settings',
      loadChildren: () => import('./pages/settings/settings.module').then(m => m.SettingsModule),
      canLoad: [ModuleAccessGuard]

    },
    {
      path: 'setup/my-profile', data: { animation: 'isRight' },
      loadChildren: () => import('./pages/my-profile/my-profile.module').then(m => m.MyProfileModule),
      canLoad: [ModuleAccessGuard]

    },

    {
      path: 'setup/change-profile', data: { animation: 'isRight' },
      loadChildren: () => import('./pages/change-profile/change-profile.module').then(m => m.ChangeProfileModule),
      canLoad: [ModuleAccessGuard]

    },
    {
      path: 'home/system-connectivity',
      loadChildren: () => import('./pages/system-connectivity/system-connectivity.module').then(m => m.SystemConnectivityModule),
      canLoad: [ModuleAccessGuard]
    },
    {
      path: 'report/system-connectivity',
      loadChildren: () => import('./pages/system-connectivity/system-connectivity.module').then(m => m.SystemConnectivityModule),
      canLoad: [ModuleAccessGuard]
    },
    //
    //
    //
    //
    {
      path: 'home/setup',
      loadChildren: () => import('./pages/setup/setup.module').then(m => m.SetupModule),
      canLoad: [ModuleAccessGuard]
    },
    {
      path: 'reports/setup',
      loadChildren: () => import('./pages/setup/setup.module').then(m => m.SetupModule),
      canLoad: [ModuleAccessGuard]
    },
    {
      path: 'system-connectivity/setup',
      loadChildren: () => import('./pages/setup/setup.module').then(m => m.SetupModule),
      canLoad: [ModuleAccessGuard]
    },
    {
      path: 'alerts/setup',
      loadChildren: () => import('./pages/setup/setup.module').then(m => m.SetupModule),
      canLoad: [ModuleAccessGuard]
    },
    {
      path: 'setup/system-info',
      loadChildren: () => import('./pages/system-info/system-info.module').then(m => m.SystemInfoModule),
      canLoad: [ModuleAccessGuard]

    },

    {
      path: 'setup/invite-members',
      loadChildren: () => import('./pages/invite-members/invite-members.module').then(m => m.InviteMembersModule),
      canLoad: [ModuleAccessGuard]

    },


    // {
    //   path: 'components',
    //   component: MaterialComponentsComponent
    // }
  ]
}, {
  path: 'accept-invitation',
  loadChildren: () => import('./pages/accept-invitation/accept-invitation.module').then(m => m.AcceptInvitationModule),
  //canLoad: [ModuleAccessGuard]

},
{
  path: 'support-request',
  loadChildren: () => import('./pages/support-request/support-request.module').then(m => m.SupportRequestModule),
//  canLoad: [ModuleAccessGuard]

},
{
  path: 'no-invites-available',
  loadChildren: () => import('./pages/invitation-empty-screen/invitation-empty-screen.module').then(m => m.EmptyInvitationModule),
  //canLoad: [ModuleAccessGuard]

},
{
  path: 'system-mapping-wizard',
  loadChildren: () => import('./pages/system-mapping-wizard/system-mapping-wizard.module').then(m => m.SystemMappingWizardModule),
 // canLoad: [ModuleAccessGuard]

},
{
  path: 'get-started',
  loadChildren: () => import('./pages/get-started/get-started.module').then(m => m.GetStartedComponentModule),
  //canLoad: [ModuleAccessGuard]

},

{
  path: 'auth',
  loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthModule)
},

{ path: '', redirectTo: 'auth', pathMatch: 'full' },
{ path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

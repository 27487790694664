import { Injectable } from '@angular/core';
import { CanLoad, Route, UrlSegment, UrlTree, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { SharedDataService } from '../services/shared-data.service';
import { NotificationService } from '../services/notification.service';

@Injectable({
  providedIn: 'root'
})
export class ModuleAccessGuard implements CanLoad {

  constructor(
    private auth: AuthService,
    private notification: NotificationService,
    private sharedData: SharedDataService,
    private router: Router
  ) { }

  canLoad(
    route: Route,
    segments: UrlSegment[]
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    console.log('Module Access Check');

    const user_details = this.sharedData.getAttribute('userDetails');
    const isLoggedIn = this.auth.isLoggedIn();
    
    // Get the current URL to avoid redirect loops
    const currentUrl = this.router.url;
    
    // Check if the user is logged in
    if (isLoggedIn) {
      console.log('User is logged in');
  

    

        return true; // Allow access if email is verified
      
    } else {
      console.log('User is not logged in');
      
      // Check if already on the login page
      if (!currentUrl.includes('/auth/login')) {
        // Redirect to login page
        return this.router.createUrlTree(['/auth/login']);
      }
      return false; // Prevent access to the guarded route
    }
  }
}

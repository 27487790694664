import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BottomBarCardService {

  private refeshClockInSubject = new BehaviorSubject<boolean>(false);

  constructor() {}

  refreshClockIn() {
    this.refeshClockInSubject.next(!this.refeshClockInSubject.value);
  }

  get refeshClockInAnimationState$() {
    return this.refeshClockInSubject.asObservable();
  }
}

import { Injectable } from '@angular/core';
import { SharedDataService } from './shared-data.service';
import { forEach } from 'lodash';
import { BehaviorSubject } from 'rxjs';
interface Reason {
  id: string | number; // ID could be a string or number, adjust based on your actual data
  reasonList: string;  // The reason list name
  // Add other properties if needed
}

interface ReasonObj {
  ver: string; // Assuming `ver` is a string (could be a number as well, modify if needed)
  records: Reason[]; // Array of `Reason` objects
}

interface Location {
  addressId: string | number; // or 'string' if IDs are strings
  regionCode: string; // Assuming regionCode is a string
  status: number; // Assuming status is a number, modify if needed
  // add any other properties for Location here
}

interface UsrObj {
  businessAddress: Location[]; // An array of Location objects
  bAddsMapped: { [key: string]: Location }; // Indexed by addressId
  bAddsByRegions: { [regionCode: string]: Location[] }; // Indexed by regionCode
  bAddsMappedActive: { [key: string]: Location }; // Indexed by addressId for active locations
  bAddsActive: Location[]; // List of active locations
}

// Define the type for the resource item
interface ResourceItem {
  id: string | number;  // Ensure id is either string or number depending on your use case
  // add other properties of the resource item as needed
}

// Define the type for the resource data
interface ResourceData {
  resourceTypesData: ResourceItem[];
  categoryData: ResourceItem[];
  orgDataData: ResourceItem[];
}

// Define the type for the resDataMapped object
interface MappedData {
  resourceData: { [key: string]: ResourceItem }; // Indexable object, where key is the id
  categoryData: { [key: string]: ResourceItem };
  orgData: { [key: string]: ResourceItem };
}


@Injectable({
  providedIn: 'root',
})
export class BobjMapService {
  private bObjSubject = new BehaviorSubject<any>(null); // Holds business data

  constructor(private sharedData: SharedDataService) {}

  // Getter to expose the bObj as an observable
  get bObj$() {
    return this.bObjSubject.asObservable();
  }

  // Method to map business data
  mapBusinessData(): void {
    this.sharedData.restoreSharedData()
    const bObj = this.sharedData.getAttribute('bObj'); // Retrieve bObj from shared data
    console.log(bObj)
    if (bObj) {
      // Alias map
      bObj.aliasMap = {};
      forEach(bObj.aliases, (item: any) => {
        bObj.aliasMap[item.aliasSlug] = item.aliasValue;
      });

      // let resDataMapped = {
      //   resourceData: {},
      //   categoryData: {},
      //   orgData: {},
      // };

      // const resourceData = bObj.resourceData;

      // if (resourceData) {
      //   forEach(resourceData.resourceTypesData, (item) => {
      //     resDataMapped.resourceData[item.id] = item;
      //   });

      //   forEach(resourceData.categoryData, (item: any) => {
      //     resDataMapped.categoryData[item.id] = item;
      //   });

      //   forEach(resourceData.orgDataData, (item: any) => {
      //     resDataMapped.orgData[item.id] = item;
      //   });
      // }
       // Initialize resDataMapped with the correct type
    const resourceData: ResourceData = bObj.resourceData;
    let resDataMapped: MappedData = {
      resourceData: {},
      categoryData: {},
      orgData: {},
    };

    // Safely map the data, TypeScript will know that item.id exists and is a valid key
    if (resourceData.resourceTypesData) {
      forEach(resourceData.resourceTypesData, (item: ResourceItem) => {
        resDataMapped.resourceData[item.id] = item;
      });
    }

    if (resourceData.categoryData) {
      forEach(resourceData.categoryData, (item: ResourceItem) => {
        resDataMapped.categoryData[item.id] = item;
      });
    }

    if (resourceData.orgDataData) {
      forEach(resourceData.orgDataData, (item: ResourceItem) => {
        resDataMapped.orgData[item.id] = item;
      });
    }

      bObj.resDataMapped = resDataMapped;

      // Region code mapping
      bObj.regionCodeMapped = {};
      forEach(bObj.regionCode, (item: any) => {
        bObj.regionCodeMapped[item.id] = item;
      });

      // Attributes mapping
      bObj.allAttributesMapped = {};
      forEach(bObj.allAttributes, (item: any) => {
        bObj.allAttributesMapped[item.id] = item.attributeName;
      });

      bObj.serialNumAttributesMapped = {};
      forEach(bObj.serialNumAttributes, (item: any) => {
        bObj.serialNumAttributesMapped[item.id] = item.attributeName;
      });

      // Business Address mapping
      bObj.bAddsMapped = {};
      bObj.bAddsByRegions = {};
      bObj.bAddsMappedActive = {};
      bObj.bAddsActive = [];

      forEach(bObj.businessAddress, (location: any) => {
        bObj.bAddsByRegions[location.regionCode] = bObj.bAddsByRegions[location.regionCode] || [];
        bObj.bAddsByRegions[location.regionCode].push(location);

        bObj.bAddsMapped[location.addressId] = location;
        if (location.status === 2) {
          bObj.bAddsMappedActive[location.addressId] = location;
          bObj.bAddsActive.push(location);
        }
      });

      // Update the BehaviorSubject with the mapped data
      this.bObjSubject.next(bObj);
console.log('here')
      // Optionally, update the shared data or localStorage if needed
      this.sharedData.setAttribute('bObj', bObj); // Save updated bObj in sharedData or localStorage
    } else {
      console.error('bObj not found in shared data!');
    }
  }

   mapUserData(): void {
    // Assuming $rootScope.usrObj is of type UsrObj

    this.sharedData.restoreSharedData()
    const usrObj: any = this.sharedData.getAttribute('usrObj');
    // Initialize the mappings
    usrObj.bAddsMapped = {};
    usrObj.bAddsByRegions = {};
    usrObj.bAddsMappedActive = {};
    usrObj.bAddsActive = [];
  
    // Loop through the business addresses
    forEach(usrObj.businessAddress, (location: Location) => {
      // Index the locations by regionCode
      usrObj.bAddsByRegions[location.regionCode] = usrObj.bAddsByRegions[location.regionCode] || [];
      usrObj.bAddsByRegions[location.regionCode].push(location);
  
      // Index the locations by addressId
      usrObj.bAddsMapped[location.addressId] = location;
  
      // If the location is active (status == 2), index it in bAddsMappedActive
      if (location.status === 2) {
        usrObj.bAddsMappedActive[location.addressId] = location;
        usrObj.bAddsActive.push(location);
      }
    });
    this.sharedData.setAttribute('usrObj',usrObj)
    // After mapping, update $rootScope.usrObj (if needed)
   
  }
  mapReasonData(): void {
    // Restore shared data (presumably from localStorage or another shared source)
    this.sharedData.restoreSharedData();

    // Get the reasonObj from shared data
    const reasonObj: ReasonObj = this.sharedData.getAttribute('rObj');

    // Initialize rObj structure in the correct format
    let rObj = {
      ver: reasonObj.ver,
      records: {} as { [key: string]: { [key: string]: Reason } } // Dynamically typed records mapping
    };

    // Loop through reasonObj.records and process each reason
    forEach(reasonObj.records, (reason: Reason) => {
      // Index by reasonList and then by reason.id
      rObj.records[reason.reasonList] = rObj.records[reason.reasonList] || {};
      rObj.records[reason.reasonList][reason.id] = reason;
    });

    // Set the processed rObj in sharedData or $rootScope
    this.sharedData.setAttribute('rObj', rObj);

  }
  
}

import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { throwError } from 'rxjs/internal/observable/throwError';
import { DialogService } from './dialog.service';
import { forEach } from 'lodash';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
// import { LoadingService } from './loader.service';
import { NotificationService } from './notification.service';
import { SharedDataService } from './shared-data.service';
import { LoadingService } from './loader.service';
import { ApiVersionService } from './api.version.service';
// import { ErrorComponent } from '../components/error/error.component';

class Info { url: any; data?: any; isLoader?: any; loaderName?: string; donot_stop_loader?: any }

@Injectable()

export class DataService {
  constructor(
    private dialog: DialogService,
    private http: HttpClient,
    private apiVersions: ApiVersionService,
    private loadingService: LoadingService,
    private message: NotificationService,
    private sharedData: SharedDataService,
    private notification: NotificationService
  ) { }

  startLoader(info: Info) {
    // Start loader before API call
    if (info.isLoader !== false) {

      this.loadingService.start(info.loaderName, 'DataService');
    }
  }

  stopLoader(info: Info, fromError?: boolean) {
    // Reset the loader
    if (info.isLoader !== false) {

      if (info.donot_stop_loader == true) {
        if (fromError == true) {
          this.loadingService.stop(info.loaderName, 'DataService');

        }
      } else {
        this.loadingService.stop(info.loaderName, 'DataService');

      }
    }
  }

  post(info: any): Observable<Response> {
    this.startLoader(info);
    const bObjVersion = this.sharedData.getAttribute('userObj')?.bzobj?.ver;  // Assuming shared data holds these versions
    const usrObjVersion = this.sharedData.getAttribute('userObj')?.usrObj?.ver;
    const reasonObjVersion = this.sharedData.getAttribute('userObj')?.reasonObj?.ver;
    info.data.bzobjver = bObjVersion;
    info.data.usrObjVer = usrObjVersion;
    info.data.reasonObjVer = reasonObjVersion;

    let str = [];
    for (let p in info.data) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(info.data[p]));
    }
    const data = str.join("&");

    console.log(data);

    return this.http.post<Response>(environment.API_URL + info.url, data).pipe(
      map((res: Response) => {
        return this.extractData(res, info);
      }),
      catchError((err: Response) => {
        return this.handleError(err, info);
      })
    );
  }

  isFile(file: any) {
    return file != null && (file instanceof window.Blob || (file.name && file.size));
  }

  addFieldToFormData(formData: FormData, val: any, key: string) {
    if (val !== undefined) {
      if (this.isFile(val)) {
        formData.append(key, val, val.name);
      } else {
        if (typeof val === 'object') {
          if (val.$$circularDetection) throw 'FileUpload: Circular reference in data. Make sure specified data has no circular reference: ' + key;

          val.$$circularDetection = true;
          try {
            for (var k in val) {
              if (val.hasOwnProperty(k) && k !== '$$circularDetection') {
                var objectKey = '[i]';
                this.addFieldToFormData(formData, val[k], key + objectKey.replace(/[ik]/g, k));
              }
            }
          } finally {
            delete val.$$circularDetection;
          }
        } else {
          formData.append(key, val);
        }
      }
    }
  }

  upload(info: any): Observable<Response> {
    this.startLoader(info);

    const formData = new FormData();

    for (const key in info.data) {
      if (info.data.hasOwnProperty(key)) {
        const val = info.data[key];
        this.addFieldToFormData(formData, val, key);
      }
    }

    const bObjVersion = this.sharedData.getAttribute('userObj')?.bzobj?.ver;  // Assuming shared data holds these versions
    const usrObjVersion = this.sharedData.getAttribute('userObj')?.usrObj?.ver;
    const reasonObjVersion = this.sharedData.getAttribute('userObj')?.reasonObj?.ver;
    this.addFieldToFormData(formData, bObjVersion, 'bzobjver');
    this.addFieldToFormData(formData, usrObjVersion, 'usrObjVer');
    this.addFieldToFormData(formData, reasonObjVersion, 'reasonObjVer');

    return this.http.post<Response>(environment.API_URL + info.url, formData).pipe(
      map((res: Response) => {
        return this.extractData(res, info);
      }),
      catchError((err: Response) => {
        return this.handleError(err, info);
      })
    );
  }

  patch(info: any): Observable<Response> {
    this.startLoader(info);
    const bObjVersion = this.sharedData.getAttribute('userObj')?.bzobj?.ver;  // Assuming shared data holds these versions
    const usrObjVersion = this.sharedData.getAttribute('userObj')?.usrObj?.ver;
    const reasonObjVersion = this.sharedData.getAttribute('userObj')?.reasonObj?.ver;
    info.data.bzobjver = bObjVersion;
    info.data.usrObjVer = usrObjVersion;
    info.data.reasonObjVer = reasonObjVersion;

    let str = [];
    for (let p in info.data) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(info.data[p]));
    }
    const data = str.join("&");

    return this.http.patch<Response>(environment.API_URL + info.url, data).pipe(
      map((res: Response) => {
        return this.extractData(res, info);
      }),
      catchError((err: Response) => {
        return this.handleError(err, info);
      })
    );
  }



  put(info: any): Observable<Response> {
    this.startLoader(info);
    const bObjVersion = this.sharedData.getAttribute('userObj')?.bzobj?.ver;  // Assuming shared data holds these versions
    const usrObjVersion = this.sharedData.getAttribute('userObj')?.usrObj?.ver;
    const reasonObjVersion = this.sharedData.getAttribute('userObj')?.reasonObj?.ver;
    info.data.bzobjver = bObjVersion;
    info.data.usrObjVer = usrObjVersion;
    info.data.reasonObjVer = reasonObjVersion;

    let str = [];
    for (let p in info.data) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(info.data[p]));
    }
    const data = str.join("&");

    return this.http.put<Response>(environment.API_URL + info.url, data).pipe(
      map((res: Response) => {
        return this.extractData(res, info);
      }),
      catchError((err: Response) => {
        return this.handleError(err, info);
      })
    );
  }
  private addAuthorizationHeader(headers: HttpHeaders): HttpHeaders {
    // Assuming you have a service or method to retrieve the token
    const token = localStorage.getItem('auth_token'); // or use a service to get the token

    if (token) {
      // Log to confirm token is added
      console.log('Authorization header added:', token);
      return headers.append('Authorization', `Bearer ${token}`);
    }

    return headers; // Return original headers if no token is available
  }
  get(info: Info): Observable<Response> {
    this.startLoader(info);

    return this.http.get<Response>(environment.API_URL + info.url).pipe(
      map((res: Response) => {
        return this.extractData(res, info);
      }),
      catchError((err: Response) => {
        return this.handleError(err, info);
      })
    );
  }

  delete(info: Info): Observable<Response> {
    this.startLoader(info);

    return this.http.delete<Response>(environment.API_URL + info.url, { body: info.data }).pipe(
      map((res: Response) => {
        return this.extractData(res, info);
      }),
      catchError((err: Response) => {
        return this.handleError(err, info);
      })
    );
  }

  extractData(res: any, info: Info) {


    if (info.donot_stop_loader == true) {
      if (res?.error) {
        this.stopLoader(info, true);
      }

    } else {
      this.stopLoader(info);

    }
    // Complete the loader as valid response is recieved
    return res;
  }

  private handleError(errorResponse: Response | any, info: Info) {
    console.log('this cdeededededededeededededed')
    // Reset the loader as the error occured
    this.stopLoader(info, true);

    // Setting all errors in an array for further processing
    let errors: any[] = [];
    forEach(errorResponse.error.error_list, function (array, key) {
      errors = errors.concat(array);
    });

    // Show toast for single error and dialog for multiple
    const errorsLen = errors.length;
    if (errorsLen === 1) {
      this.notification.toast(errors[0] || errorResponse.statusText);
    } else {

      // this.dialog.openDialogComponent(ErrorComponent, { class: [], errors: errors });
    }
    if (errorResponse.status === 422) {
      // Collect all errors from the error response
      forEach(errorResponse.error.error_list, function (array, key) {
        errors = errors.concat(array);
      });

      if (errors.length > 0) {
        errors.forEach(err => this.notification.toast(err, 'danger'));
      }
    }

    return throwError(errorResponse);
  }



  postProgress(info: Info) {
    console.log(info.data);
    this.startLoader(info);
    return this.http.post(environment.API_URL + info.url, info.data, { reportProgress: true, observe: 'events' });
  }
  // configureAPICall(xhrInfo:any) {
  //   // Escape hash mark from URL
  //   xhrInfo.url = xhrInfo.url.replace(/#/g, '%23');

  //   let headers:any = {
  //     'product': 'jacktrade_1'
  //   };

  //   // Set Accept Header with API Version or Custom Accept Header
  //   if (!xhrInfo.auc) {
  //     // Extract API Name from URL
  //     let temp = xhrInfo.url.split("Api")[0];
  //     let apiName = temp.substr(temp.lastIndexOf("/") + 1);

  //     if (this.apiVersions.getApiVersion(apiName)) {
  //       // Set the default Accept header based on API version
  //       headers.Accept = 'application/jacktrade.v' + this.apiVersions.getApiVersion(apiName) + '+json';
  //     } else {
  //       console.error('API version missing!');
  //       this.message.toast('Technical Error! Please contact administrator.', 'danger');
  //       ////////spinnerLoader.stop(xhrInfo.spinnerName);
  //       return;
  //     }

  //     // Optionally override the Accept header with a custom value (if provided)
  //     if (xhrInfo.customAcceptHeader) {
  //       headers.Accept = xhrInfo.customAcceptHeader; // Override Accept header if defined in xhrInfo
  //     }

  //     headers['time-offset'] = dateFactory.getTimeOffset();
  //     addAccessData(xhrInfo, headers);
  //   } else if (xhrInfo.addAccessDataForAuc) {
  //     addAccessData(xhrInfo, headers);
  //   }

  //   // Store the headers in xhrInfo
  //   xhrInfo.headers = headers;
  // }

}

// api-version.service.ts
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ApiVersionService {

  // A dictionary containing API names as keys and their respective versions
  private apiVersions: { [key: string]: number } = {
    business: 1,
    calendar: 1,
    customer: 1,
    jacktrade: 1,
    jtCommon: 1,
    quotes: 1,
    resources: 1,
    promotion: 1,
    invoice: 1,
    virusCheck: 1,
    deliveryZone: 1,
    deliveryMethod: 1,
    dashboardApi: 1,
    blocksApi: 1,
    dashboardPosition: 1,
    blockPosition: 1,
    quoteReport: 1,
    jobReport: 1,
    jobTrendCharts: 1,
    opportunityTrendChart: 1,
    jobsBarChart: 1,
    customerReport: 1,
    businessSetup: 1,
    taxClassification: 1,
    taxCategory: 1,
    taxZone: 1,
    dataExport: 1,
    taxMethod: 1,
    taxExempt: 1,
    taxMethodClassification: 1,
    taxMethodCalculation: 1,
    taxMethodPosition: 1,
    rental: 1,
    estimateTemplate: 1,
    lineItemTemplate: 1,
    mailBox: 1,
    webConversionSetting: 1,
    webConversionStatus: 1,
    activity: 1,
    filterSettings: 1,
    quickBookSettings: 1,
    quickBook: 1,
    company: 1,
    zoomSetting: 1,
    campaignCategory: 1,
    campaign: 1,
    campaignDrip: 1,
    campaignUnsubscribe: 1,
    exposed: 1,
    campaignReport: 1,
    assocDoc: 1,
    businessGroup: 1,
    businessCategories: 1,
    businessContact: 1,
    businessContactBulkImport: 1,
    warrantyTracking: 1,
    additionalAddress: 1,
    drawing: 1,
    serialNumbers: 1,
    kits: 1,
    personalMembers: 1,
    serialNumbersImport: 1,
    appBehaviour: 1,
    orderImportSetup: 1
    // Add other API versions here as needed
  };

  constructor() { }

  /**
   * Get the version number for a given API name.
   * If the API name is not found, return the default version (1).
   * 
   * @param apiName The name of the API whose version is requested.
   * @returns The version number of the specified API.
   */
  getApiVersion(apiName: keyof typeof this.apiVersions): number {
    return this.apiVersions[apiName] || 1;  // Default version is 1 if not found
  }

}
